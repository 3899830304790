import React from 'react';
import CrudTable from '../table/crudtable'
import { documentationTaskFilterService } from '../../_services'
import DocumentationTaskFilterForm from './documentationtaskfilterform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';

function DocumentationTaskFilter() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		type: 'ANSWER',
		position: 0,
		automatic: false,
		params: {}
	}

	const formatBoolean = (cell) => {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const formatType = (cell) => {
		if (cell == 'ANSWER') {
			return t('documentationtaskfilter.types.answer')
		} else if (cell == 'ANSWER_NOT') {
			return t('documentationtaskfilter.types.answernot')
		} else if (cell == 'PRECONDITION') {
			return t('documentationtaskfilter.types.precondition')
		} else if (cell == 'TYPE') {
			return t('documentationtaskfilter.types.type')
		} else if (cell == 'ANSWERED') {
			return t('documentationtaskfilter.types.answered')
		} else if (cell == 'UNANSWERED') {
			return t('documentationtaskfilter.types.unanswered')
		}
		return cell
	}

    const columns = [
		{
			dataField: 'customerName',
			text: t('documentationtaskfilter.columns.customer'),
			sortValue: (cell, row) => cell + '-' + row.position,
			sort: true
	    },
	    {
			dataField: 'name',
			text: t('documentationtaskfilter.columns.name'),
			sort: true
	    },
	    {
			dataField: 'type',
			text: t('documentationtaskfilter.columns.type'),
			formatter: formatType,
			sort: true
	    },
        {
			dataField: 'automatic',
			text: t('documentationtaskfilter.columns.automatic'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'position',
			text: t('documentationtaskfilter.columns.position'),
			sort: true
        }
    ]

	return (
		<div>
			<h2>
				{t('documentationtaskfilter.label')}
            </h2>
            <CrudTable
		        service={documentationTaskFilterService}
		        columns={columns}
		        initialState={initialState}
		        form={<DocumentationTaskFilterForm />}
		        sort={{dataField: 'customerName', order: 'asc'}}
		        modalSize="md"
			/>
		</div>
    )
}

export default DocumentationTaskFilter;