import React, { useContext } from 'react'
import { Form, Button, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronUp, faChevronDown, faCopy } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Node from './node';

function TextTab({state, handleChange}) {

	const {t} = useTranslation('common')

	const addReplacementText = (e, code) => {
		e.preventDefault()

		if (state.replacementTexts.some(replacementText => replacementText.code == code)) {
			return
		}

		var replacementText = {
            id: uuidv4(),
            customerId: state.customerId,
            code: code,
            translations: {
                'SV': '',
                'EN': ''
            }
        }

		var newReplacementTexts = [...state.replacementTexts, replacementText]

		handleChange({target: {type: "object", name: "replacementTexts", value: newReplacementTexts}})
	}

	const removeReplacementText = (e, replacementText) => {
        e.preventDefault()

		let newReplacementTexts = state.replacementTexts.filter(other => other.id != replacementText.id)

        handleChange({target: {type: "object", name: "replacementTexts", value: newReplacementTexts}})
    }

    const handleTranslationChange = (e, replacementText, language) => {
		replacementText.translations[language] = e.target.value

		let newReplacementTexts = [...state.replacementTexts]
		handleChange({target: {type: "object", name: "replacementTexts", value: newReplacementTexts}})
    }

	return (
		<>
			<table className="table">
				<thead>
					<tr>
						<th>
							{t('template.replacementtext.code')}
						</th>
						<th>
							{t('template.replacementtext.swedish')}
						</th>
						<th>
							{t('template.replacementtext.english')}
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{ state.replacementTexts.map(replacementText => {
						return <tr key={replacementText.id}>
							<td>
								{replacementText.code}
							</td>
							<td>
								<Form.Control
									required
									type="text"
									name="sv"
									value={replacementText.translations['SV']}
									onChange={e => handleTranslationChange(e, replacementText, 'SV')}
								/>
							</td>
							<td>
								<Form.Control
									required
									type="text"
									name="sv"
									value={replacementText.translations['EN']}
									onChange={e => handleTranslationChange(e, replacementText, 'EN')}
								/>
							</td>
							<td>
								<Button
						            onClick={(e) => removeReplacementText(e, replacementText)}
						            title={t("crudtable.delete.button")}
						            color="info"
						            variant="outline-danger">
						            <FontAwesomeIcon icon={faTrash}/>
						        </Button>
							</td>
						</tr>
					})}
				</tbody>
			</table>
	        <Dropdown>
	            <Dropdown.Toggle variant="outline-primary">
					{t('template.replacementtext.add')}
				</Dropdown.Toggle>
				<Dropdown.Menu style={{padding: '10px', minWidth: '300px'}}>
					<Dropdown.Item onClick={e => addReplacementText(e, "DOCUMENTATION_NAME")}>
						DOCUMENTATION_NAME
					</Dropdown.Item>
				</Dropdown.Menu>
	        </Dropdown>
        </>
	)
}

export default TextTab