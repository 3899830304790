import api from './api.service'

export const predefinedConfigurationService = {
    list,
    get,
    save,
    update,
    _delete
};

const BASE_URL = '/predefinedConfigurations'

function list(max) {
    return api.get(BASE_URL, {max: max || 100, sort: 'id', order: 'asc'})
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(predefinedConfiguration) {
    return api.post(BASE_URL, predefinedConfiguration)
}

function update(id, predefinedConfiguration) {
    return api.put(BASE_URL + "/" + id, predefinedConfiguration)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
