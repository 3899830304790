import api from './api.service'

export const templateService = {
    list,
    detailedList,
    getAll,
    findAllByCustomerId,
    names,
    get,
    save,
    update,
    _delete,
    copy,
    importCSV
};

const BASE_URL = '/templates'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000, sort: 'position', order: 'asc'})
}

function detailedList(max) {
    return api.get('/template/detailedList', {max : max || 1000, sort: 'position', order: 'asc'})
}

function getAll(max) {
    return api.get('/template/getAll', {max : max || 1000})
}

function findAllByCustomerId(customerId) {
    return api.get('/template/findAllByCustomerId/' + customerId)
}

function names() {
    return api.get('/template/names/')
}

function get(id) {
    return api.get(BASE_URL + "/" + id)
}

function save(template) {
    return api.post(BASE_URL, template)
}

function update(id, template) {
    return api.put(BASE_URL + "/" + id, template)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function copy(state) {
    return api.post("/template/copy", state)
}

function importCSV(data) {
	return api.post("/template/importCSV", data)
}