import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function TaskEventButton(props) {

	const task = props.task
    const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false);
    const {t} = useTranslation('common');

    const onClick = () => {
        setShow(true)
        setBackdrop(true)
    }

    const handleClose = () => {
		setShow(false)
		setBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

    return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('documentationtaskevent.label')}
	            size="sm"
	            color="info"
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faList}/>
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="lg"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{task.name}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table bordered">
						<thead>
							<tr>
								<th>
									{t('documentationtaskevent.columns.type')}
								</th>
								<th>
									{t('documentationtaskevent.columns.creator')}
								</th>
								<th>
									{t('documentationtaskevent.columns.datecreated')}
								</th>
								<th>
									{t('documentationtaskevent.columns.dateuploaded')}
								</th>
							</tr>
						</thead>
						<tbody>
							{ task.events.sort((e1, e2) => e1.dateCreated.localeCompare(e2.dateCreated)).map(event => {
								return <tr key={event.id}>
									<td>
										{event.type}
									</td>
									<td>
										{event.creator}
									</td>
									<td>
										{moment(event.dateCreated).format('YYYY-MM-DD HH:mm:ss')}
									</td>
									<td>
										{moment(event.dateUploaded).format('YYYY-MM-DD HH:mm:ss')}
									</td>
								</tr>
							})}
						</tbody>
					</table>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleClose}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default TaskEventButton