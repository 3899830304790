import React from 'react'
import User from './user'
import Customer from './customer/customer'
import CustomerStatistics from './customerstatistics'
import DomainChangeLog from './domainchangelog'
import ChangeLog from './changelog'
import { DocumentationList, DocumentationDetails, DocumentationForm } from './documentation'
import { DocumentationGroupList, DocumentationGroupDetails } from './documentationgroup'
import { DocumentationComparisonList, DocumentationComparisonDetails } from './documentationcomparison'
import { FormMonthList, FormMonthDetails } from './formmonth'
import { TemplateList, TemplateForm, TemplateDetails } from './template'
import CopyTemplate from './copytemplate'
import CopyGraphic from './copygraphic'
import License from './license'
import ExportConfiguration from './exportconfiguration'
import SuggestionImport from './suggestionimport'
import DocumentationAlias from './documentationalias'
import PhotoAlias from './photoalias'
import Graphic from './graphic'
import SuggestionList from './suggestionlist'
import Map from './map'
import SwitchUser from './switchuser'
import SwitchCustomer from './switchcustomer'
import SwitchBack from './switchback'
import LastAuthenticationSuccessEvent from './lastauthenticationsuccessevent'
import Device from "./device";
import Quartz from './quartz'
import { ChangeTemplateList, ChangeTemplateDetails } from './changetemplate'
import DeleteDocumentations from './deletedocumentations'
import DocumentationTaskFilter from './documentationtaskfilter'
import MergeDocumentations from './mergedocumentations'
import { ExportDocumentConfigurationList, ExportDocumentConfigurationForm,
	ExportDocumentConfigurationDetails } from './exportdocumentconfiguration'
import Home from './home.component'
import Logout from './logout.component'
import Forbidden from './forbidden.component'
import { ProtectedRoute } from "./protected.route";
import PageNavbar from "./navbar.component"
import AlertComponent from './alert.component'
import { Switch, Route } from 'react-router-dom';
import {Col, Container} from 'react-bootstrap'
import RefreshToken from "./refreshtoken.component";
import Profile from "./profile.component";
import ExpectedAnswerList from './expectedanswerlist'
import ExpectedAnswerImport from './expectedanswerimport'
import UploadFiles from './uploadfiles'
import UploadLater from './uploadlater'
import TempFile from './tempfile'
import Demo from './demo'
import PredefinedConfiguration from './predefinedconfiguration'
import ImportCSV from './importcsv'

function DefaultContainer() {
    return (
		<>
			<PageNavbar />
			<RefreshToken />
			<Container fluid>
				<Col md={{ span: 12, offset: 0 }}>
					<AlertComponent />
					<Switch>
						<ProtectedRoute exact path="/" component={Home}/>
						<ProtectedRoute exact path="/user" component={User} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/customer" component={Customer} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/customerstatistics" component={CustomerStatistics} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/domainChangeLog" component={DomainChangeLog} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/changeLog" component={ChangeLog} permission={["ROLE_CHANGE_LOG_READ"]}/>
						<ProtectedRoute exact path="/documentation/details/:uuid" component={DocumentationDetails} permission={["ROLE_DOCUMENTATION_READ"]}/>
						<ProtectedRoute exact path="/documentation/:uuid" component={DocumentationForm} permission={["ROLE_CREATE_DOCUMENTATION"]}/>
						<ProtectedRoute exact path="/documentation" component={DocumentationList} permission={["ROLE_DOCUMENTATION_READ"]}/>
						<ProtectedRoute exact path="/documentationGroup/:documentationName" component={DocumentationGroupDetails} permission={["ROLE_DOCUMENTATION_READ"]} product={["DOCUMENTATION_GROUP"]}/>
						<ProtectedRoute exact path="/documentationGroup" component={DocumentationGroupList} permission={["ROLE_DOCUMENTATION_READ"]} product={["DOCUMENTATION_GROUP"]}/>
						<ProtectedRoute exact path="/documentationComparison/:documentationName" component={DocumentationComparisonDetails} permission={["ROLE_DOCUMENTATION_READ"]} product={["DOCUMENTATION_COMPARISON"]}/>
						<ProtectedRoute exact path="/documentationComparison" component={DocumentationComparisonList} permission={["ROLE_DOCUMENTATION_READ"]} product={["DOCUMENTATION_COMPARISON"]}/>
						<ProtectedRoute exact path="/formMonth/:documentationName" component={FormMonthDetails} permission={["ROLE_DOCUMENTATION_READ"]} product={["FORM_MONTH"]}/>
						<ProtectedRoute exact path="/formMonth" component={FormMonthList} permission={["ROLE_DOCUMENTATION_READ"]} product={["FORM_MONTH"]}/>
						<ProtectedRoute exact path="/template/details/:uuid" component={TemplateDetails} permission={["ROLE_TEMPLATE_READ"]}/>
						<ProtectedRoute exact path="/template/:uuid" component={TemplateForm} permission={["ROLE_TEMPLATE_WRITE"]}/>
						<ProtectedRoute exact path="/template" component={TemplateList} permission={["ROLE_TEMPLATE_READ"]}/>
						<ProtectedRoute exact path="/copyTemplate" component={CopyTemplate} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/copyGraphic" component={CopyGraphic} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/license" component={License} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/exportConfiguration" component={ExportConfiguration} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/documentationAlias" component={DocumentationAlias} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/photoAlias" component={PhotoAlias} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/suggestionImport" component={SuggestionImport} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/graphic" component={Graphic} permission={["ROLE_GRAPHIC_READ"]}/>
						<ProtectedRoute exact path="/suggestionList" component={SuggestionList} permission={["ROLE_SUGGESTION_LIST_READ"]}/>
						<ProtectedRoute exact path="/map" component={Map} permission={["ROLE_DOCUMENTATION_READ"]}/>
						<ProtectedRoute exact path="/switchuser" component={SwitchUser} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/switchcustomer" component={SwitchCustomer} permission={["ROLE_SWITCH_CUSTOMER"]}/>
						<ProtectedRoute exact path="/switchback" component={SwitchBack} permission={["ROLE_SWITCHED_USER"]}/>
						<ProtectedRoute exact path="/lastAuthenticationSuccessEvent" component={LastAuthenticationSuccessEvent} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/quartz" component={Quartz} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/changeTemplate/:uuid" component={ChangeTemplateDetails} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/changeTemplate" component={ChangeTemplateList} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/deleteDocumentations" component={DeleteDocumentations} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/documentationTaskFilter" component={DocumentationTaskFilter} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/device" component={Device} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/mergeDocumentations" component={MergeDocumentations} permission={["ROLE_DOCUMENTATION_WRITE"]}/>
						<ProtectedRoute exact path="/exportDocumentConfiguration/details/:uuid" component={ExportDocumentConfigurationDetails} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/exportDocumentConfiguration/:uuid" component={ExportDocumentConfigurationForm} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/exportDocumentConfiguration" component={ExportDocumentConfigurationList} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/profile" component={Profile} />
						<ProtectedRoute exact path="/expectedAnswerList" component={ExpectedAnswerList} permission={["ROLE_EXPECTED_ANSWER_LIST_WRITE"]}/>
						<ProtectedRoute exact path="/expectedAnswerImport" component={ExpectedAnswerImport} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/tempFile" component={TempFile} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/demo" component={Demo} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/predefinedConfiguration" component={PredefinedConfiguration} permission={["ROLE_SUPER_ADMIN"]}/>
						<ProtectedRoute exact path="/importCSV" component={ImportCSV} permission={["ROLE_SUPER_ADMIN"]}/>
						<Route exact path="/logout" component={Logout}/>
						<Route exact path="/forbidden" component={Forbidden}/>
						<Route exact path="/uploadFiles" component={UploadFiles}/>
						<Route exact path="/uploadLater" component={UploadLater}/>
						<Route path="*" component={() => "404 NOT FOUND"}/>
					</Switch>
				</Col>
			</Container>
		</>
    )
}

 export default DefaultContainer;