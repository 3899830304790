import React, {useState, useEffect} from 'react';
import { templateService, customerService } from '../../_services'
import { useTranslation } from "react-i18next";
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import Select from 'react-select'

function ImportCSV() {

	const [customers, setCustomers] = useState([])
	const [selectedCustomer, setSelectedCustomer] = useState(null)
	const [templates, setTemplates] = useState([])
	const [selectedTemplate, setSelectedTemplate] = useState(null)
	const [fileData, setFileData] = useState(null)
	const [columnSeparator, setColumnSeparator] = useState(",")
	const dispatch = useDispatch();
	const {t} = useTranslation('common')

	useEffect(() => {
		templateService.getAll().then(templates => {
			templates.sort((a, b) => a.name.localeCompare(b.name))
			setTemplates(templates)
	    })
	    customerService.getOptions().then(customers => {
		    customers.sort((a, b) => a.name.localeCompare(b.name))
			setCustomers(customers)
			if (!selectedCustomer && customers.length > 0) {
				setSelectedCustomer(customers[0])
            }
	    })
	}, [])

	const handleCustomerChange = e => {
		var customerId = e.target.value
		var customer = customers.find(customer => customer.id == customerId)
		setSelectedCustomer(customer)
	}

	const handleTemplateChange = template => {
		setSelectedTemplate(template)
	}

	const handleFileChange = e => {
		let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                setFileData(hexString)
            });
            reader.readAsBinaryString(file)
        }
	}

	const submit = function(event) {
		console.log('submit')

		event.preventDefault()
		event.stopPropagation()

		if (!selectedTemplate) {
			return
		}

		var data = {
			template: selectedTemplate.id,
			data: fileData,
			columnSeparator: columnSeparator
		}

		templateService.importCSV(data).then(result =>  {
			console.log(result)
		})

//		event.preventDefault()
//        event.stopPropagation()
//
//        console.log(state.templates)
//        console.log(state.customer)
//        console.log(state.name)
//
//		setShowTemplatesEmptyError(false)
//        if (!state.templates || state.templates.length == 0) {
//            setShowTemplatesEmptyError(true)
//            return
//        }
//
//		setShowNameNotUniqueError(false)
//        var existingTemplate = templates.find(template => template.name == state.name && template.customerId == state.customer)
//        if (existingTemplate) {
//            setShowNameNotUniqueError(true)
//            return
//        }
//
//        templateService.copy(state).then(data =>  {
//			console.log(data)
//			dispatch(alertActions.success(t('alerts.createsuccessful')))
//
//			templateService.getAll().then(templates => {
//				templates.sort((a, b) => a.name.localeCompare(b.name))
//				setTemplates(templates)
//		    })
//		})
	}

	return (
		<Container>
			<h2>
				{t('importcsv.label')}
			</h2>
			{ templates && customers &&
				<Row>
					<Col md={{ span: 6, offset: 0 }}>
						<Form id="data-form" onSubmit={submit}>
							<Form.Group controlId="customer">
				                <Form.Label>
				                    {t('importcsv.columns.customer')} *
				                </Form.Label>
				                <Form.Control required as="select" placeholder={t('importcsv.columns.customer')} name="customer" onChange={handleCustomerChange}>
				                    { customers.map(customer => (
				                        <option key={customer.id} value={customer.id}>{customer.name}</option>
				                    ))}
				                </Form.Control>
				            </Form.Group>
				            <Form.Group controlId="template">
				                <Form.Label>
				                    {t('importcsv.columns.template')} *
				                </Form.Label>
				                { selectedCustomer &&
					                <Select
					                    placeholder={t('importcsv.columns.template')}
					                    isLoading={templates.length === 0}
					                    options={templates.filter(template => template.customerId == selectedCustomer.id)}
					                    value={selectedTemplate}
					                    getOptionLabel={template => template.name}
					                    getOptionValue={template => template.id}
					                    onChange={handleTemplateChange}
					                    required
					                />
				                }
				            </Form.Group>
				            <Form.Group controlId="file">
				                <Form.Label>
				                    {t('importcsv.columns.file')} *
				                </Form.Label>
				                <Form.Control type="file" accept=".csv" name="file" onChange={handleFileChange}/>
				            </Form.Group>
				            <Form.Group controlId="columnSeparator">
				                <Form.Label>
				                    {t('importcsv.columns.columnseparator')} *
				                </Form.Label>
					            <Form.Control required as="select" name="columnSeparator" value={columnSeparator} onChange={e => setColumnSeparator(e.target.value)}>
				                    <option value=",">Komma (,)</option>
				                    <option value=";">Semikolon (;)</option>
				                </Form.Control>
			                </Form.Group>
						</Form>
						<Button form='data-form' type="submit" variant="primary">
							{t('importcsv.import')}
						</Button>
					</Col>
				</Row>
			}
		</Container>
	)
}

export default ImportCSV;