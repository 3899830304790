import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'
import CrudTable from '../table/crudtable'
import { customerService } from '../../_services'
import CustomerForm from './customerform.component'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { SERVER_URL } from '../../config';
import UserList from './userlist';
import PhotoDump from './photodump';
import InvoiceAttachement from './invoiceattachement'

function Customer() {

	const [backdrop, setBackdrop] = useState(false)
	const [users, setUsers] = useState([])
	const [selectedCustomer, setSelectedCustomer] = useState(null)
    const {t} = useTranslation('common')

	const initialState = {
		name: '',
		products: [],
		exportDocumentTypes: [],
		exportDocumentConfigurations: [],
		documentationStatuses: []
	}

    const formatUsers = function(cell) {
		return <UserList users={cell}/>
    }

	const formatProducts = function(cell) {
	    if (cell && cell.length > 0) {
	        cell.sort((a, b) => a.id - b.id)
	        return cell.map(product => product.name).join(", ")
	    }
	    return '-'
    }

    const formatTotalSize = function(cell, row) {
	    if (cell) {
			var i = Math.floor( Math.log(cell) / Math.log(1000) );
	        return ( cell / Math.pow(1000, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
	    }
	    return 0
    }

    const formatExtras = function(cell) {
	    return <span style={{whiteSpace: 'nowrap'}}>
			<PhotoDump customerId={cell}/>
			<span>&nbsp;&nbsp;</span>
			<InvoiceAttachement customerId={cell}/>
		</span>
    }

    const columns = [
	    {
			dataField: 'name',
			text: t('customer.columns.name'),
			sort: true
	    },
	    {
	        dataField: 'products',
	        text: t('customer.columns.products'),
	        formatter: formatProducts,
	        filterValue: formatProducts
	    },
	    {
	        dataField: 'users',
	        text: t('customer.columns.users'),
	        formatter: formatUsers,
	        filterValue: formatUsers
	    },
	    {
	        dataField: 'documentationCount',
	        text: t('customer.columns.documentationcount'),
	        sort: true
	    },
	    {
	        dataField: 'photoCount',
	        text: t('customer.columns.photocount'),
	        sort: true
	    },
	    {
	        dataField: 'totalSize',
	        text: t('customer.columns.totalsize'),
	        formatter: formatTotalSize,
	        sort: true
	    },
	    {
	        dataField: 'id',
	        text: '',
	        formatter: formatExtras
	    }
	]

	return (
        <div>
            <h2>
                {t('customer.label')}
            </h2>
			<CrudTable
				service={customerService}
				columns={columns}
				initialState={initialState}
				form={<CustomerForm />}
				sort={{dataField: 'name', order: 'asc'}}
				modalSize="lg"
			/>
        </div>
	)
}

export default Customer;