import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { documentationTaskService, suggestionListService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";

function EditTaskButton(props) {

	const [state, setState] = useState(props.data)
	const [suggestionList, setSuggestionList] = useState(null)
	const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false);
    const {t} = useTranslation('common');

    const onClick = () => {
        setState(props.data)
        setValidated(false)
        setShow(true)
        setBackdrop(true)

		var type = props.data.type
        if (type == "SINGLE_CHOICE_SUGGESTION" || type == "MULTI_CHOICE_SUGGESTION") {
            suggestionListService.get(props.data.options).then(data => {
	            setSuggestionList(data)
	        })
        }
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    const form = event.currentTarget
	    if (form.checkValidity() === false) {
	        event.stopPropagation()
	        setValidated(true)
	    } else {
	        handleConfirm()
			setValidated(false)
	    }
	}

	const handleConfirm = () => {
        handleClose()

        var newState = {
            id: state.id,
            answer: state.answer
        }

        documentationTaskService.update(newState.id, newState).then(result => {
            props.onUpdate(result)
        })
    }

    const handleClose = () => {
		setShow(false)
		setBackdrop(false)
		document.body.className = ""
		document.body.style = '';
    }

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    var value = target.value

	    if (state.type == 'MULTI_CHOICE' || state.type == 'MULTI_CHOICE_SUGGESTION') {
			var answers = state.answer ? state.answer.split(';') : []

			if (target.checked) {
				answers.push(target.name)
			} else {
				answers = answers.filter(answer => answer != target.name)
			}

			value = answers.join(';')
	    } else if (target.type === 'checkbox') {
	        value = target.checked ? state.options : ''
	    }

	    setState( prevState => ({
	        ...prevState,
	        answer: value
	    }));
	}

    return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('crudtable.edit')}
	            size="sm"
	            color="info"
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faEdit}/>
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('documentation.editanswer')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
						<label>
							{state.name} {state.required == 'YES' ? '*' : ''}
						</label>
						<br/>
						<i>{state.description}</i>
						{ state.type == 'TEXT' &&
							<Form.Group controlId="answer">
				                <Form.Control
				                    type="text"
				                    name="answer"
				                    value={state.answer || ''}
				                    onChange={handleChange}
				                    required={state.required == 'YES'}
				                />
				            </Form.Group>
						}
						{ state.type == 'CHECKBOX' &&
			                <Form.Label>
		                        <Form.Check
		                            id={state.id}
		                            type="checkbox"
		                            name="answer"
		                            label={state.options}
		                            checked={state.answer}
		                            onChange={handleChange}
		                            required={state.required == 'YES'}
		                        />
		                    </Form.Label>
						}
						{ state.type == 'SINGLE_CHOICE' &&
							<Form.Group controlId="answer">
				                <Form.Control
			                        as="select"
			                        name="answer"
			                        value={state.answer}
			                        onChange={handleChange}
			                        required={state.required == 'YES'}
			                    >
			                        <option value="">Välj svar</option>
			                        { state.options.split(";").map(option => {
			                            return <option key={option} value={option}>{option}</option>
			                        })}
				                </Form.Control>
				            </Form.Group>
						}
						{ state.type == 'MULTI_CHOICE' &&
			                <>
			                    { state.options.split(";").map(option => {
			                        return <div key={option}>
			                            <Form.Label>
					                        <Form.Check
					                            id={state.id + option}
					                            type="checkbox"
					                            name={option}
					                            label={option}
					                            checked={state.answer && state.answer.split(';').includes(option)}
					                            onChange={handleChange}
					                            required={state.required == 'YES' && !state.answer}
					                        />
					                    </Form.Label>
				                    </div>
			                    })}
			                </>
						}
						{ state.type == 'INTEGER' &&
							<Form.Group controlId="answer">
				                <Form.Control
				                    type="number"
				                    name="answer"
				                    value={state.answer}
				                    onChange={handleChange}
				                    required={state.required == 'YES'}
				                />
				            </Form.Group>
						}
						{ state.type == 'DECIMAL' &&
							<Form.Group controlId="answer">
				                <Form.Control
				                    type="number"
				                    name="answer"
				                    value={state.answer}
				                    onChange={handleChange}
				                    required={state.required == 'YES'}
				                />
				            </Form.Group>
						}
						{ state.type == 'SINGLE_CHOICE_SUGGESTION' &&
							<Form.Group controlId="answer">
				                <Form.Control
			                        as="select"
			                        name="answer"
			                        value={state.answer}
			                        onChange={handleChange}
			                        required={state.required == 'YES'}
			                    >
			                        <option value="">Välj svar</option>
			                        { suggestionList &&
			                            <>
			                                { suggestionList.suggestions.map(suggestion => {
					                            return <option key={suggestion.id} value={suggestion.text}>{suggestion.text}</option>
					                        })}
			                            </>
			                        }
				                </Form.Control>
				            </Form.Group>
						}
						{ state.type == 'MULTI_CHOICE_SUGGESTION' &&
							<>
								{ suggestionList &&
									<>
					                    { suggestionList.suggestions.map(suggestion => {
					                        return <div key={suggestion.text}>
					                            <Form.Label>
							                        <Form.Check
							                            id={suggestion.text}
							                            type="checkbox"
							                            name={suggestion.text}
							                            label={suggestion.text}
							                            checked={state.answer && state.answer.split(';').includes(suggestion.text)}
							                            onChange={handleChange}
							                        />
							                    </Form.Label>
						                    </div>
					                    })}
				                    </>
			                    }
			                </>
						}
		            </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' type="submit" variant="primary">
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default EditTaskButton