import React, { useState } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, Dropdown, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faArrowsRotate, faTable, faFont, faCamera, faImage, faTableColumns,
	faHeading, faShoePrints, faBars, faBolt, faX, faChevronUp, faChevronDown, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select'

function Word({nodes, addNode, removeNode, onDragEnd, toggleNode}) {

	const allowChildren = ['HEADER', 'FOOTER', 'EACH', 'TABLE', 'ROW', 'COLUMN']

	const {t} = useTranslation('common');

	return (
		<div>
			<DragDropContext onDragEnd={result => onDragEnd(result)}>
				<Droppable droppableId="1">
					{ provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{ nodes.map((node, index) => {
								return <Draggable key={node.id} draggableId={node.id} index={index}>
									{ provided => (
										<Node
											provided={provided}
											node={node}
											toggleNode={toggleNode}
											addNode={addNode}
											removeNode={removeNode}
											onDragEnd={onDragEnd}
										/>
									)}
								</Draggable>
							})}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
            </DragDropContext>
            <Dropdown>
				<Dropdown.Toggle variant="outline-primary">
					<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item onClick={e => addNode(e, null, 'HEADER')}>
						{t('exportdocumentconfiguration.nodetypes.header')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'FOOTER')}>
						{t('exportdocumentconfiguration.nodetypes.footer')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'EACH')}>
						{t('exportdocumentconfiguration.nodetypes.each')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'TABLE')}>
						{t('exportdocumentconfiguration.nodetypes.table')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'TEXT')}>
						{t('exportdocumentconfiguration.nodetypes.text')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'PHOTO')}>
						{t('exportdocumentconfiguration.nodetypes.photo')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'PAGE_BREAK')}>
						{t('exportdocumentconfiguration.nodetypes.pagebreak')}
					</Dropdown.Item>
					<Dropdown.Item onClick={e => addNode(e, null, 'LINE_BREAK')}>
						{t('exportdocumentconfiguration.nodetypes.linebreak')}
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	)

	function Node({node, parent, provided}) {

		const [state, setState] = useState(node.params)

	    const handleChange = e => {
	        var name = e.target.name
            var value = e.target.type === 'checkbox' ? e.target.checked : e.target.value

			var newState = {...state}
			newState[name] = value
            setState(newState)

            node.params[name] = value
	    }

	    const handleFilterChange = e => {
	        console.log(e)

	        var newState = {...state}
	        newState['FILTERS'] = e
	        setState(newState)

	        node.params['FILTERS'] = e
	    }

	    const getIcon = type => {
			switch (type) {
				case 'HEADER':
					return <FontAwesomeIcon icon={faHeading}/>
				case 'FOOTER':
					return <FontAwesomeIcon icon={faShoePrints}/>
				case 'EACH':
					return <FontAwesomeIcon icon={faArrowsRotate}/>
				case 'TABLE':
					return <FontAwesomeIcon icon={faTable}/>
				case 'ROW':
					return <FontAwesomeIcon icon={faBars}/>
				case 'COLUMN':
					return <FontAwesomeIcon icon={faBars} className="fa-rotate-90"/>
				case 'TEXT':
					return <FontAwesomeIcon icon={faFont}/>
				case 'PHOTO':
					return <FontAwesomeIcon icon={faCamera}/>
				case 'IMAGE':
					return <FontAwesomeIcon icon={faImage}/>
				case 'PAGE_BREAK':
					return <FontAwesomeIcon icon={faBolt}/>
				case 'LINE_BREAK':
					return <FontAwesomeIcon icon={faArrowTurnDown} rotation={90}/>
				default:
					return <></>
			}
		}

		const getFilterOptions = (value) => {
			if (value == 'PHOTOS') {
				return ['TASK']
			}
			return ['GROUPS', 'CHILDREN', 'DOCUMENTATION_TASK_FILTER', 'EMPTY', 'NOT_EMPTY', 'ANSWERED', 'UNANSWERED', 'HAS_PHOTOS', 'HAS_NO_PHOTOS', 'ANSWERED_OR_HAS_PHOTOS']
	    }

	    const getFilterLabel = (filter) => {
	        switch (filter) {
	            case 'GROUPS':
	                return t('exportdocumentconfiguration.params.values.groups')
	            case 'CHILDREN':
	                return t('exportdocumentconfiguration.params.values.children')
	            case 'DOCUMENTATION_TASK_FILTER':
	                return t('exportdocumentconfiguration.params.values.documentationtaskfilter')
	            case 'EMPTY':
	                return t('exportdocumentconfiguration.params.values.empty')
	            case 'NOT_EMPTY':
	                return t('exportdocumentconfiguration.params.values.notempty')
	            case 'ANSWERED':
	                return t('exportdocumentconfiguration.params.values.answered')
	            case 'UNANSWERED':
	                return t('exportdocumentconfiguration.params.values.unanswered')
	            case 'HAS_PHOTOS':
	                return t('exportdocumentconfiguration.params.values.hasphotos')
	            case 'HAS_NO_PHOTOS':
	                return t('exportdocumentconfiguration.params.values.hasnophotos')
	            case 'ANSWERED_OR_HAS_PHOTOS':
	                return t('exportdocumentconfiguration.params.values.answeredorhasphotos')
	        }
	        return ''
	    }

	    return (
	        <div {...provided.draggableProps} ref={provided.innerRef}>
	            <Card>
	                <Card.Body>
	                    <div className="clearfix">
							<div className="float-left">
								<span {...provided.dragHandleProps}>
									<FontAwesomeIcon icon={faGripVertical}/>
								</span>
								<span style={{paddingLeft: '20px', paddingRight: '5px'}}>
									{getIcon(node.type)}
								</span>
	                            {node.type}
							</div>
							<div className="float-right">
								{ node.type != 'PAGE_BREAK' && node.type != 'LINE_BREAK' &&
									<>
										<Button variant="outline-secondary" size="sm" onClick={e => toggleNode(node)}>
											<FontAwesomeIcon icon={node.collapsed ? faChevronDown : faChevronUp}/>
										</Button>
										&nbsp;
									</>
								}
	                            <Button variant="outline-danger" size="sm" onClick={e => removeNode(e, node, parent)}>
									<FontAwesomeIcon icon={faTrash}/>
								</Button>
					        </div>
						</div>
						{ !node.collapsed &&
							<div>
				                { node.type == 'EACH' &&
				                    <>
				                        <Form.Group controlId="in">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.iterateover')}
							                </Form.Label>
											<Form.Control required as="select" name="IN" value={state['IN']} onChange={handleChange}>
												<option value="">{t('exportdocumentconfiguration.params.values.choose')}</option>
												<option value={'PHOTOS'}>{t('exportdocumentconfiguration.params.values.photos')}</option>
												<option value={'TASKS'}>{t('exportdocumentconfiguration.params.values.tasks')}</option>
												<option value={'UNEXPECTED_ANSWER_EVENTS'}>{t('exportdocumentconfiguration.params.values.unexpectedanswerevents')}</option>
							                </Form.Control>
						                </Form.Group>
						                { (node.params['IN'] == 'TASKS' || node.params['IN'] == 'PHOTOS') &&
						                    <>
							                    <Form.Group controlId="filter">
							                        <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.filters')}
									                </Form.Label>
							                        <Select
									                    placeholder={t('exportdocumentconfiguration.params.keys.filters')}
									                    options={getFilterOptions(node.params['IN'])}
									                    value={state['FILTERS']}
									                    getOptionLabel={filter => getFilterLabel(filter)}
									                    getOptionValue={filter => filter}
									                    isMulti={true}
									                    closeMenuOnSelect={false}
									                    onChange={handleFilterChange}
						                            />
								                </Form.Group>
							                </>
						                }
				                    </>
				                }
				                { (node.type == 'TABLE') &&
				                    <>
				                        <Form.Group controlId="removeborders">
											<Form.Label>
							                    <Form.Check type="checkbox" name="REMOVE_BORDERS" label={t('exportdocumentconfiguration.params.keys.removeborders')} checked={node.params['REMOVE_BORDERS'] || false} onChange={handleChange}/>
							                </Form.Label>
						                </Form.Group>
				                    </>
				                }
				                { (node.type == 'COLUMN') &&
					                <Row>
					                    <Col md="3">
							                <Form.Group controlId="bgcolor">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.backgroundcolor')}
								                </Form.Label>
												<InputGroup>
													<Form.Control type="color" name="BACKGROUND_COLOR" value={node.params['BACKGROUND_COLOR'] || '#FFFFFF'} onChange={handleChange}/>
							                        { node.params['BACKGROUND_COLOR'] &&
								                        <Button variant="outline-secondary" onClick={e => handleChange({target: {name: 'BACKGROUND_COLOR', value: ''}})}>
								                            <FontAwesomeIcon icon={faX} style={{color: 'red'}}/>
				                                        </Button>
			                                        }
							                    </InputGroup>
							                </Form.Group>
						                </Col>
					                    <Col md="3">
							                <Form.Group controlId="width">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.width')}
								                </Form.Label>
								                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH'] || ''} onChange={handleChange}/>
							                </Form.Group>
						                </Col>
						                <Col md="3">
							                <Form.Group controlId="colspan">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.colspan')}
								                </Form.Label>
								                <Form.Control type="number" name="COLSPAN" value={node.params['COLSPAN'] || ''} onChange={handleChange}/>
							                </Form.Group>
						                </Col>
						                <Col md="3">
							                <Form.Group controlId="alignment">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.alignment')}
								                </Form.Label>
								                <Form.Control as="select" name="ALIGNMENT" value={node.params['ALIGNMENT']} onChange={handleChange}>
								                    <option value="">{t('exportdocumentconfiguration.params.values.none')}</option>
													<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
													<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
													<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
								                </Form.Control>
							                </Form.Group>
						                </Col>
					                </Row>
				                }
				                { (node.type == 'CHILD') &&
				                    <>
						                <Form.Group controlId="name">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.name')}
							                </Form.Label>
							                <Form.Control required name="NAME" value={node.params['NAME']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { node.type == 'TEXT' &&
				                    <>
					                    <Form.Group controlId="text">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.text')}
							                </Form.Label>
							                <TextareaAutosize required className="form-control" placeholder={t('exportdocumentconfiguration.params.keys.text')} name="TEXT" value={node.params['TEXT']} onChange={handleChange}/>
						                </Form.Group>
						                <Row>
							                <Col md="3">
								                <Form.Group controlId="size">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.size')}
									                </Form.Label>
									                <Form.Control required type="number" name="SIZE" value={node.params['SIZE']} onChange={handleChange}/>
								                </Form.Group>
							                </Col>
							                <Col md="3">
								                <Form.Group controlId="color">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.color')}
									                </Form.Label>
													<Form.Control type="color" name="COLOR" value={node.params['COLOR'] || '#000000'} onChange={handleChange}/>
								                </Form.Group>
							                </Col>
							                <Col md="3">
								                <Form.Group controlId="style">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.style')}
									                </Form.Label>
									                <Form.Control required as="select" name="STYLE" value={node.params['STYLE']} onChange={handleChange}>
														<option value={'NORMAL'}>{t('exportdocumentconfiguration.params.values.normal')}</option>
														<option value={'BOLD'}>{t('exportdocumentconfiguration.params.values.bold')}</option>
														<option value={'ITALIC'}>{t('exportdocumentconfiguration.params.values.italic')}</option>
														<option value={'BOLD_ITALIC'}>{t('exportdocumentconfiguration.params.values.bolditalic')}</option>
									                </Form.Control>
								                </Form.Group>
						                    </Col>
						                    <Col md="3">
								                <Form.Group controlId="alignment">
								                    <Form.Label>
									                    {t('exportdocumentconfiguration.params.keys.alignment')}
									                </Form.Label>
									                <Form.Control required as="select" name="ALIGNMENT" value={node.params['ALIGNMENT']} onChange={handleChange}>
														<option value={'LEFT'}>{t('exportdocumentconfiguration.params.values.left')}</option>
														<option value={'CENTER'}>{t('exportdocumentconfiguration.params.values.center')}</option>
														<option value={'RIGHT'}>{t('exportdocumentconfiguration.params.values.right')}</option>
									                </Form.Control>
								                </Form.Group>
						                    </Col>
						                </Row>
				                    </>
				                }
				                { node.type == 'PHOTO' &&
				                    <Row>
				                        <Col md="4">
							                <Form.Group controlId="width">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.width')}
								                </Form.Label>
								                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
							                </Form.Group>
							            </Col>
							            <Col md="4">
							                <Form.Group controlId="height">
							                    <Form.Label>
								                    {t('exportdocumentconfiguration.params.keys.height')}
								                </Form.Label>
								                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
							                </Form.Group>
					                    </Col>
					                    <Col md="4">
							                <Form.Group controlId="compress">
							                    <br/>
												<Form.Label>
								                    <Form.Check type="checkbox" name="COMPRESS" label={t('exportdocumentconfiguration.params.keys.compress')} checked={node.params['COMPRESS'] == null ? true : node.params['COMPRESS']} onChange={handleChange}/>
								                </Form.Label>
							                </Form.Group>
					                    </Col>
					                </Row>
				                }
				                { node.type == 'IMAGE' &&
				                    <>
				                        <Form.Group controlId="data">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.data')}
							                </Form.Label>
							                { node.params['DATA'] &&
							                    <div>
							                        <img src={'data:' + node.params['TYPE'] + ';base64, ' + node.params['DATA']} style={{width: '100px'}} alt=""/>
							                    </div>
							                }
											<Form.Control required={!node.params['DATA']} type="file" accept="image/png, image/jpeg" name="DATA" onChange={handleChange}/>
						                </Form.Group>
						                <Form.Group controlId="width">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.width')}
							                </Form.Label>
							                <Form.Control type="number" name="WIDTH" value={node.params['WIDTH']} onChange={handleChange}/>
						                </Form.Group>
						                <Form.Group controlId="height">
						                    <Form.Label>
							                    {t('exportdocumentconfiguration.params.keys.height')}
							                </Form.Label>
							                <Form.Control type="number" name="HEIGHT" value={node.params['HEIGHT']} onChange={handleChange}/>
						                </Form.Group>
				                    </>
				                }
				                { allowChildren.includes(node.type) &&
				                    <>
				                        <label>
				                            {t('exportdocumentconfiguration.children')}:
				                        </label>
						                <div>
						                    <DragDropContext onDragEnd={result => onDragEnd(result, node)}>
						                        <Droppable droppableId={node.id}>
						                            { provided => (
						                                <div ref={provided.innerRef} {...provided.droppableProps}>
										                    { node.children.map((child, index) => {
										                        return <Draggable key={child.id} draggableId={child.id} index={index}>
										                            { provided => (
											                            <Node
												                            provided={provided}
																			node={child}
																			parent={node}
																		/>
																	)}
																</Draggable>
										                    })}
										                    {provided.placeholder}
									                    </div>
						                            )}
						                        </Droppable>
						                    </DragDropContext>
						                </div>
						                <Dropdown>
											<Dropdown.Toggle variant="outline-primary">
												<FontAwesomeIcon icon={faPlus}/> {t('exportdocumentconfiguration.addnode')}
											</Dropdown.Toggle>
											<Dropdown.Menu>
												{ node.type == 'HEADER' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TABLE')}>
															{t('exportdocumentconfiguration.nodetypes.table')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'FOOTER' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TABLE')}>
															{t('exportdocumentconfiguration.nodetypes.table')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'EACH' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'EACH')}>
															{t('exportdocumentconfiguration.nodetypes.each')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PHOTO')}>
															{t('exportdocumentconfiguration.nodetypes.photo')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PAGE_BREAK')}>
															{t('exportdocumentconfiguration.nodetypes.pagebreak')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'LINE_BREAK')}>
															{t('exportdocumentconfiguration.nodetypes.linebreak')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'TABLE' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'ROW')}>
															{t('exportdocumentconfiguration.nodetypes.row')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'ROW' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'COLUMN')}>
															{t('exportdocumentconfiguration.nodetypes.column')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'COLUMN' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'CHILD')}>
															{t('exportdocumentconfiguration.nodetypes.child')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'PHOTO')}>
															{t('exportdocumentconfiguration.nodetypes.photo')}
														</Dropdown.Item>
														<Dropdown.Item onClick={e => addNode(e, node, 'IMAGE')}>
															{t('exportdocumentconfiguration.nodetypes.image')}
														</Dropdown.Item>
													</>
												}
												{ node.type == 'CHILD' &&
													<>
														<Dropdown.Item onClick={e => addNode(e, node, 'TEXT')}>
															{t('exportdocumentconfiguration.nodetypes.text')}
														</Dropdown.Item>
													</>
												}
											</Dropdown.Menu>
										</Dropdown>
									</>
								}
							</div>
						}
					</Card.Body>
				</Card>
			</div>
	    )
	}
}

export default Word