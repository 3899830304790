import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { documentationComparisonService, templateService } from '../../_services'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Spinner } from 'react-bootstrap'

function DocumentationComparisonList() {

	const [documentationGroups, setDocumentationGroups] = useState([])
	const [exportEvents, setExportEvents] = useState([])
	const [templates, setTemplates] = useState([])
	const [loading, setLoading] = useState(false)
	const {t} = useTranslation('common')

    useEffect(() => {
        setLoading(true)
        documentationComparisonService.list(10000).then(result => {
            var documentations = result.documentations
            setExportEvents(result.exportEvents)

            var documentationGroupMap = new Map()
            documentations.forEach(documentation => {
                var documentationGroup = documentationGroupMap.get(documentation.name)
				if (!documentationGroup) {
					documentationGroup = []
					documentationGroupMap.set(documentation.name, documentationGroup)
				}
				documentationGroup.push(documentation)
            })

			templateService.list(1000).then(templates => {
	            templates.sort((a, b) => {
	                if (a.position != b.position) {
	                    return a.position - b.position
	                }
	                return a.name.localeCompare(b.name)
	            })
	            setTemplates(templates)

	            var documentationGroups = Array.from(documentationGroupMap, ([name, documentations]) => {
	                var maxDate = ""
	                documentations.forEach(documentation => {
	                    if (documentation.dateCreated > maxDate) {
	                        maxDate = documentation.dateCreated
	                    }
	                })
	                var documentationGroup = {
	                    name: name,
	                    documentations: documentations,
	                    templates: [],
	                    maxDate: maxDate
	                }
					templates.forEach(template => {
						var templateDocumentations = documentations.filter(documentation => documentation.templateId == template.id)
						if (templateDocumentations && templateDocumentations.length > 0) {
							documentationGroup.templates.push(template)
						}
					})
	                return documentationGroup
	            })
	            setDocumentationGroups(documentationGroups)

	            setLoading(false)
	        })
        })
    }, [])

    const formatName = function(cell) {
		return <a href={'/documentationComparison/' + cell}>{cell}</a>
	}

	const formatAlias = function(cell, row) {
		return cell || '-'
	}

    const formatTemplates = function(cell, row) {
        if (row.templates && row.templates.length > 0) {
            return row.templates.map(template => template.name).join(", ")
        }
		return '-'
	}

	const formatDate = function(cell, row) {
        if (cell) {
            return moment(cell).format('yyyy-MM-DD HH:mm')
        }
		return '-'
	}

	const formatExported = function(cell, row) {
		console.log('formatExported')
		var exportedDocumentations = 0
        row.documentations.forEach(documentation => {
            if (exportEvents.some(exportEvent => exportEvent.documentation == documentation.id && exportEvent.success)) {
                exportedDocumentations++
            }
        })
        if (exportedDocumentations == row.documentations.length) {
			return t('crudtable.yes')
		}
		return t('crudtable.no') + ' (' + exportedDocumentations + '/' + row.documentations.length + ')'
	}

    const { SearchBar } = Search;

    const pagination = paginationFactory({
		sizePerPage: 25,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		paginationTotalRenderer: (from, to, size) => {
			return ' ' + t('crudtable.total', {from: from, to: to, size: size})
		},
		nextPageTitle: t('crudtable.next'), // the title of next page button
		prePageTitle: t('crudtable.previous'), // the title of previous page button
		firstPageTitle: t('crudtable.first'), // the title of first page button
		lastPageTitle: t('crudtable.last')
    })

    const NoDataIndication = () => (
	    <div>
	        {loading &&
			    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'50px', marginBottom:'50px'}}>
					<Spinner animation="border" role="status">
						<span className="sr-only">{t('crudtable.loading')}</span>
					</Spinner>
			    </div>
		    }
		    {!loading &&
		        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'10px', marginBottom:'10px'}}>
	                <span>{t('crudtable.nodatafound')}</span>
	            </div>
		    }
	    </div>
    )

    const columns = [
	    {
			dataField: 'name',
			text: t('documentation.columns.name'),
			formatter: formatName,
			sort: true
	    },
	    {
			dataField: 'alias',
			text: t('documentation.columns.alias'),
			formatter: formatAlias,
			sort: true
	    },
	    {
			dataField: 'templates',
			text: t('documentationcomparison.columns.templates'),
			formatter: formatTemplates,
			filterValue: formatTemplates,
			sort: true
	    },
	    {
			dataField: 'maxDate',
			text: t('documentationcomparison.columns.date'),
			formatter: formatDate,
			filterValue: formatDate,
			sort: true
	    },
	    {
			dataField: 'exported',
			text: t('documentationcomparison.columns.exported'),
			formatter: formatExported,
			filterValue: formatExported,
			sortValue: formatExported,
			sort: true
	    }
    ]

	return (
		<div>
			<ToolkitProvider
				bootstrap4
				keyField='name'
				data={documentationGroups}
				columns={columns}
				search
			>
                { toolkitprops => (
                    <div>
                        <SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
                        <BootstrapTable
							remote={{ filter: false, search: false, pagination: false }}
							noDataIndication={ () => <NoDataIndication /> }
							condensed={true}
							pagination={pagination}
							{...toolkitprops.baseProps}
							onTableChange={() => console.log("onTableChange")}
							sort={{dataField: 'maxDate', order: 'desc'}}
                        />
                    </div>
                )}
	        </ToolkitProvider>
        </div>
    )
}

export default DocumentationComparisonList;