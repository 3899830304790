import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { authService, templateService } from '../../_services'
import { Row, Col, Button, Modal, Card, Tabs, Tab } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolder, faSquareCheck, faCamera, faPaintbrush, faFont, faHashtag, faListCheck,
	faMinus, faPlus, faInfo, faPaperclip, faEdit, faGear, faHeading } from '@fortawesome/free-solid-svg-icons'
import styles from './details.module.css';
import { history } from '../../_helpers';

function TemplateDetails() {

	const [template, setTemplate] = useState(null)
	const [tasks, setTasks] = useState([])
	const [customers, setCustomers] = useState([])
	const [selectedTask, setSelectedTask] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [backdrop, setBackdrop] = useState(false)
	const {uuid} = useParams()
    const {t} = useTranslation('common')

    useEffect(() => {
		templateService.get(uuid).then(template => {
			template.tasks.forEach(task => {
				task.expanded = true
				task.hasChildren = template.tasks.some(child => child.parent == task.id)
			})
			setTemplate(template)
			setTasks(template.tasks)
        })
    }, [])

    function showTaskTypes() {
        if (template && authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
            let customer = customers.find(customer => customer.id == template.customerId)
			if (customer) {
				return customer.products.some(product => product.name === "FORM")
			}
        }
		return authService.hasProducts(['FORM'])
    }

    const getTemplateTaskRow = (task, depth) => {
        return <React.Fragment key={task.id}>
			<tr>
				<td style={{paddingLeft: (12 + (25 * depth)) + 'px'}}>
					{ task.type == 'GROUP' && task.hasChildren &&
						<a onClick={e => toggleGroup(e, task)} style={{cursor: 'pointer', marginRight: '10px'}}>
							<FontAwesomeIcon icon={task.expanded ? faMinus : faPlus}/>
						</a>
					}
					{ task.type == 'GROUP' && !task.hasChildren &&
						<span style={{marginRight: '10px', color: 'lightgray'}}>
							<FontAwesomeIcon icon={faPlus}/>
						</span>
					}
					{getDocumentationTaskIcon(task)} {task.name}
				</td>
				<td>
					{task.description || '-'}
				</td>
				<td>
					{task.options || '-'}
				</td>
				<td>
					{ task.required == 'YES' && t('task.required.yes')}
					{ task.required == 'NO' && t('task.required.no')}
					{ task.required == 'NO_WITH_VALID_EXPLANATION' && t('task.required.nowithvalidexplanation')}
					{ task.required == 'NO_WITH_LATER_UPLOAD' && t('task.required.nowithlaterupload')}
				</td>
				{ showTaskTypes() &&
					<>
						<td>
							{ task.preconditions.length > 0 &&
								<a href="#" onClick={e => {showPreconditions(e, task)}} style={{cursor: 'pointer'}}>
									{t('Visa villkor')}
								</a>
							}
						</td>
					</>
				}
			</tr>
			{ task.type == 'GROUP' && task.expanded &&
				tasks.filter(child => child.parent == task.id).map(child => {
					return getTemplateTaskRow(child, depth + 1)
				})
			}
		</React.Fragment>
    }

    const getDocumentationTaskIcon = (documentationTask) => {
	    switch (documentationTask.type) {
			case 'PHOTO':
				return <FontAwesomeIcon icon={faCamera}/>
			case 'DRAWING':
				return <FontAwesomeIcon icon={faPaintbrush}/>
			case 'TEXT':
			case 'STATIC_TEXT':
				return <FontAwesomeIcon icon={faFont}/>
			case 'HEADER':
				return <FontAwesomeIcon icon={faHeading}/>
			case 'INTEGER':
			case 'DECIMAL':
				return <FontAwesomeIcon icon={faHashtag}/>
			case 'CHECKBOX':
				return <FontAwesomeIcon icon={faSquareCheck}/>
			case 'SINGLE_CHOICE':
			case 'MULTI_CHOICE':
			case 'SINGLE_CHOICE_SUGGESTION':
			case 'MULTI_CHOICE_SUGGESTION':
				return <FontAwesomeIcon icon={faListCheck}/>
			case 'GROUP':
				return <FontAwesomeIcon icon={faFolder}/>
			case 'FILE':
				return <FontAwesomeIcon icon={faPaperclip}/>
			case 'PREDEFINED':
				return <FontAwesomeIcon icon={faGear}/>
		}
		return <FontAwesomeIcon icon={faCamera}/>
    }

    const toggleGroup = (e, groupTask) => {
        console.log('toggleGroup')

        e.preventDefault();

        groupTask.expanded = !groupTask.expanded

		let newTasks = [...tasks]
		setTasks(newTasks)
    }

    const closeAll = (e) => {
        e.preventDefault()
        tasks.forEach(task => {
            if (task.type == 'GROUP') {
                task.expanded = false
            }
        })
        let newTasks = [...tasks]
		setTasks(newTasks)
    }

    const openAll = (e) => {
        e.preventDefault()
        tasks.forEach(task => {
            if (task.type == 'GROUP') {
                task.expanded = true
            }
        })
        let newTasks = [...tasks]
		setTasks(newTasks)
    }

    const showPreconditions = (e, task) => {
        e.preventDefault();
        setSelectedTask(task);
        setShowModal(true);
    }

    const hideModal = () => {
		setShowModal(false)
		setBackdrop(false)
    }

    const getNodeCard = node => {
        return <Card key={node.id + '-card'}>
            <Card.Header style={{ background: 'none' }}>
                {node.type}
            </Card.Header>
            { node.params && Object.entries(node.params).length > 0 &&
	            <Card.Body>
	                { node.params && Object.entries(node.params).length > 0 &&
	                    <ul>
	                        { Object.entries(node.params).map(param => {
	                            return <li key={node.id + '-' + param[0]}>
	                                {param[0]}: {formatParamValue(param[1])}
	                            </li>
	                        })}
	                    </ul>
	                }
	                { node.children && node.children.length > 0 &&
	                    <>
	                        { node.children.map(child => {
	                            return getNodeCard(child)
	                        })}
	                    </>
	                }
	            </Card.Body>
            }
        </Card>
    }

    const formatParamValue = value => {
        if (typeof value == "boolean") {
            return value ? t('crudtable.yes') : t('crudtable.no')
        }
        return value
    }

	return (
		<div style={{marginBottom: '50px'}}>
			{ template &&
				<div style={{marginBottom: 20}}>
					<div className="clearfix">
						<div className="float-left">
							<h2>
								{ template.name }
				            </h2>
			            </div>
			            <div className="float-right">
			                <Button
					            onClick={e => history.push("/template/" + uuid)}
					            title={t('crudtable.edit')}
					            color="info"
					            variant="outline-secondary">
					            <FontAwesomeIcon icon={faEdit}/> {t('crudtable.edit')}
					        </Button>
			            </div>
		            </div>
		            <Row className={styles.templateDetails}>
		                <Col xl="3">
		                    <div>
			                    <label>
			                        {t('template.columns.description')}
			                    </label>
			                    { template.description || '-' }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.hidden')}
			                    </label>
			                    { template.hidden ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.exportdocumenttypes')}
			                    </label>
			                    { template.exportDocumentTypes.length > 0 &&
	                                <>
	                                    {template.exportDocumentTypes.map(exportDocumentType => {
	                                        return <span>{exportDocumentType}</span>
	                                    })}
	                                </>
	                            }
	                            { template.exportDocumentTypes.length == 0 &&
	                                <>-</>
	                            }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.exportdocumentconfigurations')}
			                    </label>
			                    { template.exportDocumentConfigurations.length > 0 &&
	                                <>
	                                    {template.exportDocumentConfigurations.map(exportDocumentConfiguration => {
	                                        return exportDocumentConfiguration.name
	                                    }).join(", ")}
	                                </>
	                            }
	                            { template.exportDocumentConfigurations.length == 0 &&
	                                <>-</>
	                            }
		                    </div>
		                </Col>
		                <Col xl="3">
		                    <div>
			                    <label>
			                        {t('template.columns.includeexternalfiles')}
			                    </label>
			                    { template.includeExternalFiles ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.locationenabled')}
			                    </label>
			                    { template.locationenabled ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.locationrequired')}
			                    </label>
			                    { template.locationrequired ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
				            <div>
			                    <label>
			                        {t('template.columns.deleteautomatically')}
			                    </label>
			                    { template.deleteAutomatically ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.deletetimelimit')}
			                    </label>
			                    { template.deleteTimeLimit || '-' }
		                    </div>
	                    </Col>
			            <Col xl="3">
		                    <div>
			                    <label>
			                        {t('template.columns.position')}
			                    </label>
			                    { template.position }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.suggestionlist')}
			                    </label>
			                    { template.suggestionListName }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.onlysuggestionsallowed')}
			                    </label>
			                    { template.onlySuggestionsAllowed ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.graphicenabled')}
			                    </label>
			                    { template.graphicEnabled ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
		                    <div>
			                    <label>
			                        {t('template.columns.predefinedenabled')}
			                    </label>
			                    { template.predefinedEnabled ? t('crudtable.yes') : t('crudtable.no') }
		                    </div>
				        </Col>
				    </Row>
	            </div>
			}
			{ template &&
				<>
					<Tabs style={{paddingTop: '10px'}}>
						<Tab eventKey="tasks" title={t('template.tabs.tasks')} style={{paddingTop: '10px'}}>
							{ showTaskTypes() && tasks.some(task => task.type == 'GROUP') &&
								<>
									<Button
							            onClick={openAll}
							            size="md"
							            color="info"
							            variant="outline-primary">
							            {t('template.openall')}
							        </Button>
									<Button
							            onClick={closeAll}
							            size="md"
							            color="info"
							            variant="outline-primary"
							            style={{marginRight: '5px'}}>
							            {t('template.closeall')}
							        </Button>
						        </>
					        }
							<table className="table border">
								<thead>
									<tr>
										<th>
											{t('task.columns.name')}
										</th>
										<th>
											{t('task.columns.description')}
										</th>
										<th>
											{t('task.columns.options')}
										</th>
										<th>
											{t('task.columns.required')}
										</th>
										{ showTaskTypes() &&
											<>
												<th>
													{t('task.columns.preconditions')}
												</th>
											</>
										}
									</tr>
								</thead>
								<tbody>
									{ tasks.filter(task => task.parent == null).map(task => {
										return getTemplateTaskRow(task, 0)
									})}
								</tbody>
							</table>
						</Tab>
						{ template.templateExportConfigurations.length > 0 &&
							<Tab eventKey="export" title={t('template.tabs.export')} style={{paddingTop: '10px'}}>
								<Row>
									<Col xl="12">
	                                    { template.templateExportConfigurations.sort((a, b) => a.position - b.position).map(templateExportConfiguration => {
	                                        return <Card key={templateExportConfiguration.id} style={{marginBottom: '10px'}}>
												<Card.Header style={{ background: 'none' }}>
													<h4 style={{ marginBottom: 0 }}>
														{templateExportConfiguration.exportConfigurationName}
													</h4>
												</Card.Header>
												<Card.Body>
		                                            { templateExportConfiguration.params &&
		                                                <ul>
		                                                    { Object.entries(templateExportConfiguration.params).map(param => {
				                                                return <li key={templateExportConfiguration.id + '-' + param[0]}>
				                                                    {param[0]}: {formatParamValue(param[1])}
				                                                </li>
				                                            })}
		                                                </ul>
		                                            }
		                                            { templateExportConfiguration.nodes.map(node => {
		                                                return getNodeCard(node)
		                                            })}
	                                            </Card.Body>
	                                        </Card>
	                                    })}
							        </Col>
						        </Row>
							</Tab>
						}
					</Tabs>
					<Modal
						style={{overflow: 'scroll'}}
						size="lg"
						show={showModal}
						backdrop={backdrop}
						onShow={() => console.log('onShow')}
						onHide={hideModal}
						animation={false}>
						<Modal.Header closeButton>
							<Modal.Title>
								{selectedTask ? selectedTask.name : ''}
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Row>
								{ selectedTask && selectedTask.preconditions.map((precondition, index) => {
									return <Col sm={4} key={precondition.id}>
										<Card>
											<Card.Header style={{ background: 'none' }}>
												{t('precondition.label')} {index + 1}
											</Card.Header>
											<Card.Body>
												<label>
													{t('precondition.columns.type')}
												</label>
												<div>
													{ precondition.type == 'TASK_ANSWER' && t('precondition.types.taskanswer')}
													{ precondition.type == 'TASK_PHOTOS_TAKEN' && t('precondition.types.taskphotostaken')}
													{ precondition.type == 'TASK_NO_PHOTOS_TAKEN' && t('precondition.types.tasknophotostaken')}
												</div>
								                { precondition.type == 'TASK_ANSWER' &&
								                    <>
								                        <label>
															{t('precondition.params.task')}
														</label>
														<div>
															{ tasks.find(otherTask => otherTask.id == precondition.params.TASK_ID).name }
														</div>
										                <label>
															{t('precondition.params.operator')}
														</label>
														<div>
															{ precondition.params.OPERATOR == 'EQUALS' && t('precondition.operators.equals')}
															{ precondition.params.OPERATOR == 'NOT_EQUALS' && t('precondition.operators.notequals')}
														</div>
										                <label>
															{t('precondition.params.answer')}
														</label>
														<div>
															{precondition.params.ANSWER}
														</div>
													</>
							                    }
							                    { (precondition.type == 'TASK_PHOTOS_TAKEN' || precondition.type == 'TASK_NO_PHOTOS_TAKEN') &&
								                    <>
								                        <label>
															{t('precondition.params.task')}
														</label>
														<div>
															{ tasks.find(otherTask => otherTask.id == precondition.params.TASK_ID).name }
														</div>
							                        </>
							                    }
							                </Card.Body>
										</Card>
									</Col>
								})}
							</Row>
						</Modal.Body>
						<Modal.Footer>
							<Button
					            onClick={hideModal}
					            size="md"
					            color="info"
					            variant="primary">
					            {t('crudtable.ok')}
					        </Button>
						</Modal.Footer>
					</Modal>
				</>
			}
        </div>
    )
}

export default TemplateDetails;