import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Col, Tabs, Tab, Card, Spinner, Dropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { exportDocumentConfigurationService } from '../../_services';
import { history } from '../../_helpers';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faArrowsRotate, faTable, faFont, faCamera, faImage, faTableColumns,
	faHeading, faShoePrints, faBars, faBolt, faX, faChevronUp, faChevronDown, faArrowTurnDown, faScaleBalanced,
	faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import styles from './details.module.css';

function ExportDocumentConfigurationDetails() {

	const { uuid } = useParams()
	const [exportDocumentConfiguration, setExportDocumentConfiguration] = useState(null)
    const {t} = useTranslation('common')

    useEffect(() => {
        exportDocumentConfigurationService.get(uuid).then(data => {
            setExportDocumentConfiguration(data)
        })
    }, [])

    const getIcon = type => {
		switch (type) {
			case 'HEADER':
				return <FontAwesomeIcon icon={faHeading}/>
			case 'FOOTER':
				return <FontAwesomeIcon icon={faShoePrints}/>
			case 'EACH':
				return <FontAwesomeIcon icon={faArrowsRotate}/>
			case 'TABLE':
				return <FontAwesomeIcon icon={faTable}/>
			case 'ROW':
				return <FontAwesomeIcon icon={faBars}/>
			case 'COLUMN':
				return <FontAwesomeIcon icon={faBars} className="fa-rotate-90"/>
			case 'TEXT':
				return <FontAwesomeIcon icon={faFont}/>
			case 'PHOTO':
				return <FontAwesomeIcon icon={faCamera}/>
			case 'IMAGE':
				return <FontAwesomeIcon icon={faImage}/>
			case 'PAGE_BREAK':
				return <FontAwesomeIcon icon={faBolt}/>
			case 'LINE_BREAK':
				return <FontAwesomeIcon icon={faArrowTurnDown} rotation={90}/>
			case 'TASK':
				return <FontAwesomeIcon icon={faMagnifyingGlass}/>
			case 'IF':
				return <FontAwesomeIcon icon={faScaleBalanced}/>
			default:
				return <></>
		}
	}

	const getNodeRow = (node, depth) => {
        return <React.Fragment key={node.id}>
            <tr>
                <td style={{paddingLeft: 10 + (depth * 20)}}>
                    {getIcon(node.type)} {node.type}
                </td>
                <td>
                    {Object.entries(node.params).map(param => param[0] + ': ' + formatParam(node, param)).join(', ')}
                    { node.type == 'IMAGE' &&
                        <div>
                            <img src={'data:image/png;base64, ' + node.params.DATA}/>
                        </div>
                    }
				</td>
			</tr>
			{ node.children.map(child => {
				return getNodeRow(child, depth+1)
			})}
		</React.Fragment>
    }

    const formatParam = (node, param) => {
        if (node.type == 'IMAGE' && param[0] == 'DATA') {
            return '-'
        }
//        if (typeof value == "boolean") {
//            return value ? t('crudtable.yes') : t('crudtable.no')
//        }
        return param[1]
    }

    return (
        <div className={styles.exportDocumentConfigurationDetails}>
            { exportDocumentConfiguration &&
	            <Row>
	                <Col md="12">
	                    <div>
		                    <label>
		                        {t('exportdocumentconfiguration.columns.name')}
		                    </label>
		                    { exportDocumentConfiguration.name || '-' }
	                    </div>
	                    <div>
		                    <label>
		                        {t('exportdocumentconfiguration.columns.description')}
		                    </label>
		                    { exportDocumentConfiguration.description || '-' }
	                    </div>
	                    <div>
		                    <label>
		                        {t('exportdocumentconfiguration.columns.type')}
		                    </label>
		                    { exportDocumentConfiguration.type || '-' }
	                    </div>
	                    <div>
		                    <label>
		                        {t('exportdocumentconfiguration.columns.nodes')}
		                    </label>
		                    <table className="table table-bordered">
		                        <thead>
		                            <tr>
		                                <th>
		                                    Namn
		                                </th>
		                                <th>
		                                    Params
		                                </th>
		                            </tr>
		                        </thead>
		                        <tbody>
		                            { exportDocumentConfiguration.nodes.filter(node => node.parent == null).map(node => {
										return getNodeRow(node, 0)
									})}
		                        </tbody>
		                    </table>
	                    </div>
		            </Col>
	            </Row>
            }
        </div>
    )
}

export default ExportDocumentConfigurationDetails