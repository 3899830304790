import api from './api.service'

export const licenseService = {
    list,
    simpleList,
    get,
    save,
    update,
    _delete,
    deleteLicenseAndTemplates
};

const BASE_URL = '/licenses'

function list(max) {
    return api.get(BASE_URL, {max : max || 1000})
}

function simpleList(max) {
    return api.get('/license/simpleList', {max : max || 1000})
}

function get(id) {
    return api.get(BASE_URL + '/' + id)
}

function save(license) {
    return api.post(BASE_URL, license)
}

function update(id, license) {
    return api.put(BASE_URL + "/" + id, license)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}

function deleteLicenseAndTemplates(id) {
    return api.delete('/license/deleteLicenseAndTemplates/' + id)
}
