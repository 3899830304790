import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { customerService } from '../../_services'

const DocumentationTaskFilterForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (customers.length === 0) {
            customerService.getOptions().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (data.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
    })

    const handleTypeChange = (e) => {
        console.log('handleTypeChange')
        let value = e.target.value
        if (value == 'ANSWER') {
            data.params = {ANSWER: ''}
        } else if (value == 'ANSWER_NOT') {
            data.params = {ANSWER: ''}
        } else if (value == 'PRECONDITION') {
            data.params = { PRECONDITION_TYPE: 'TASK_ANSWER', OPERATOR: 'EQUALS', ANSWER: '' }
        }
        handleChange({target: {type: "select", name: "type", value: value}})
        handleChange({target: {type: "object", name: "params", value: data.params}})
    }

    const handleParamsChange = (e) => {
        console.log('handleParamsChange')
        let value = e.target.value
        let name = e.target.name
        data.params[name] = value
        handleChange({target: {type: "object", name: "params", value: data.params}})
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            <Form.Group controlId="customerId">
                <Form.Label>
                    {t('documentationtaskfilter.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
	                <Form.Label>
	                    {t('documentationtaskfilter.columns.name')} *
	                </Form.Label>
	                <Form.Control required type="text" placeholder={t('documentationtaskfilter.columns.name')} name="name" value={data.name} onChange={handleChange}/>
	            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('documentationtaskfilter.columns.type')}
                </Form.Label>
                <Form.Control required as="select" name="type" value={data.type} onChange={handleTypeChange}>
                    <option value="ANSWER">{t('documentationtaskfilter.types.answer')}</option>
                    <option value="ANSWER_NOT">{t('documentationtaskfilter.types.answernot')}</option>
                    <option value="PRECONDITION">{t('documentationtaskfilter.types.precondition')}</option>
                    <option value="TASK_TYPE">{t('documentationtaskfilter.types.tasktype')}</option>
                    <option value="ANSWERED">{t('documentationtaskfilter.types.answered')}</option>
                    <option value="UNANSWERED">{t('documentationtaskfilter.types.unanswered')}</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="position">
                <Form.Label>
                    {t('documentationtaskfilter.columns.position')} *
                </Form.Label>
                <Form.Control required type="number" placeholder={t('documentationtaskfilter.columns.position')} name="position" value={data.position} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="automatic">
                <Form.Label>
                    <Form.Check type="checkbox" name="automatic" label={t('documentationtaskfilter.columns.automatic')} checked={data.automatic} onChange={handleChange}/>
                </Form.Label>
            </Form.Group>
            { data.type == 'ANSWER' &&
                <Form.Group controlId="answer">
	                <Form.Label>
	                    {t('documentationtaskfilter.params.answer')} *
	                </Form.Label>
	                <Form.Control required type="text" placeholder={t('documentationtaskfilter.params.answer')} name="ANSWER" value={data.params.ANSWER} onChange={handleParamsChange}/>
	            </Form.Group>
            }
            { data.type == 'ANSWER_NOT' &&
                <Form.Group controlId="answer">
	                <Form.Label>
	                    {t('documentationtaskfilter.params.answer')} *
	                </Form.Label>
	                <Form.Control required type="text" placeholder={t('documentationtaskfilter.params.answer')} name="ANSWER" value={data.params.ANSWER} onChange={handleParamsChange}/>
	            </Form.Group>
            }
            { data.type == 'PRECONDITION' &&
                <>
                    <Form.Group controlId="preconditiontype">
	                    <Form.Control required as="select" name="PRECONDITION_TYPE" value={data.params.PRECONDITION_TYPE} onChange={handleParamsChange}>
		                    <option value="TASK_ANSWER">{t('precondition.types.taskanswer')}</option>
		                    <option value="TASK_PHOTOS_TAKEN">{t('precondition.types.taskphotostaken')}</option>
		                    <option value="TASK_NO_PHOTOS_TAKEN">{t('precondition.types.tasknophotostaken')}</option>
		                </Form.Control>
	                </Form.Group>
	                { data.params.PRECONDITION_TYPE == 'TASK_ANSWER' &&
	                    <>
			                <label>
								{t('precondition.params.operator')}
							</label>
							<Form.Control required as="select" name="OPERATOR" value={data.params.OPERATOR} onChange={handleParamsChange}>
			                    <option value="EQUALS">{t('precondition.operators.equals')}</option>
			                    <option value="NOT_EQUALS">{t('precondition.operators.notequals')}</option>
			                </Form.Control>
			                <label>
								{t('precondition.params.answer')}
							</label>
							<Form.Control required type="text" placeholder={t('precondition.params.answer')} name="ANSWER" value={data.params.ANSWER} onChange={handleParamsChange}/>
                        </>
                    }
                </>
            }
        </Form>
    )
}

export default DocumentationTaskFilterForm