import React from 'react';
import CrudTable from '../table/crudtable'
import { userService } from '../../_services'
import UserForm from './userform.component'
import { useTranslation } from "react-i18next";

function User() {

    const {t} = useTranslation('common');

    const initialState = {
		customer: -1,
		parent: '',
		username: '',
		password: '',
		email: '',
		fullName: '',
		enabled: true,
		accountExpired: false,
		accountLocked: false,
		passwordExpired: false,
		twoFactorAuthentication: false,
		userRoles: [],
		userLicenses: [],
		parent: null
    }

	const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
	}

	const formatUserRoles = function(cell) {
		if (cell && cell.length > 0) {
			return cell.map(userRole => userRole.roleAuthority).join(', ')
		}
		return '-'
	}

	const formatUserLicenses = function(cell) {
		if (cell && cell.length > 0) {
			return cell.map(userLicense => userLicense.licenseCode).join(', ')
		}
		return '-'
	}

    const columns = [
		{
	        dataField: 'username',
	        text: t('user.columns.username'),
	        sort: true
        },
        {
			dataField: 'customerName',
		    text: t('user.columns.customer'),
		    sort: true
        },
        {
	        dataField: 'enabled',
	        text: t('user.columns.enabled'),
	        sort: true,
	        formatter: formatBoolean,
	        filterValue: formatBoolean
	    },
	    {
	        dataField: 'userRoles',
	        text: t('user.columns.roles'),
	        sort: true,
	        formatter: formatUserRoles,
	        filterValue: formatUserRoles
	    },
	    {
	        dataField: 'userLicenses',
	        text: t('user.columns.licenses'),
	        sort: true,
	        formatter: formatUserLicenses,
	        filterValue: formatUserLicenses
	    },
	    {
	        dataField: 'twoFactorAuthentication',
	        text: t('user.columns.twofactorauthentication'),
	        sort: true,
	        formatter: formatBoolean,
	        filterValue: formatBoolean
        },
	    {
	        dataField: 'parentUsername',
	        text: t('user.columns.parent'),
	        sort: true,
	        formatter: cell => cell || '-'
        }
    ]

    return (
		<div>
            <h2>
                {t('user.label')}
            </h2>
            <CrudTable
                service={userService}
                columns={columns}
                initialState={initialState}
		        form={<UserForm />}
                remote={{"filter": false, "search": false, "pagination": false}}
                sort={{dataField: 'username', order: 'asc'}}
                modalSize="lg"
            />
        </div>
    )
}

export default User;