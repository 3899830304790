import React, {useState, useEffect} from 'react'
import { Form, Button, InputGroup, Dropdown } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { authService, customerService, predefinedConfigurationService } from '../../_services'
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

const PredefinedConfigurationForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
	const [predefinedConfigurations, setPredefinedConfigurations] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
        if (customers.length === 0) {
            customerService.getOptions().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (data.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
//        if (predefinedConfigurations.length === 0) {
//	        predefinedConfigurationService.list().then(data => {
//	            data.sort((a, b) => a.name.localeCompare(b.name))
//	            setPredefinedConfigurations(data)
//	        })
//        }
    })

    function handleCustomerChange(e) {
        let value = e.target.value

        handleChange(e)
    }

    function handleTypeChange(e) {
        e.preventDefault()

		var newParams = {}
        if (e.target.value === 'NEXT') {
            newParams.token = ''
        } else if (e.target.value === 'SHAREPOINT_FOLDERS') {
			newParams.clientId = ''
            newParams.tenantId = ''
			newParams.siteUrl = ''
			newParams.scope = ''
			newParams.path = 'Shared Documents'
        }

        handleChange({target: {type: "object", name: "params", value: newParams}})
        handleChange({target: {type: "select", name: "type", value: e.target.value}})
    }

    function handleValueChange(e, key) {
        let value = e.target.value

        let newParams = {...data.params}
        newParams[key] = value

        handleChange({target: {type: "object", name: "params", value: newParams}})
    }

    function handlePredefinedConfigurationChange(e) {
        var value = e.target.value
        if (value != -1) {
            var predefinedConfiguration = predefinedConfigurations.find(predefinedConfiguration => predefinedConfiguration.id == e.target.value)
	        handleChange({target: {type: "text", name: "name", value: predefinedConfiguration.name}})
	        handleChange({target: {type: "text", name: "type", value: predefinedConfiguration.type}})
	        handleChange({target: {type: "object", name: "params", value: {...predefinedConfiguration.params}}})
        }
    }

    return (
        <Form id="data-form" noValidate  onSubmit={handleSubmit} validated={validated}>
            { !data.dateCreated &&
                <Form.Group controlId="predefinedConfiguration">
                    <Form.Label>
	                    {t('predefinedconfiguration.copyfrom')}
	                </Form.Label>
					<Form.Control required as="select" name="template" value={-1} onChange={handlePredefinedConfigurationChange}>
						<option value={-1}>{t('predefinedconfiguration.chooseconfiguration')}</option>
	                    {predefinedConfigurations.map(predefinedConfiguration => (
	                        <option key={predefinedConfiguration.id} value={predefinedConfiguration.id}>
	                            {predefinedConfiguration.name} ({predefinedConfiguration.customerName})
	                        </option>
	                    ))}
	                </Form.Control>
                </Form.Group>
            }
            <Form.Group controlId="customerId">
                <Form.Label>
                    {t('predefinedconfiguration.columns.customer')}
                </Form.Label>
                <Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleCustomerChange}>
                    {customers.map(customer => (
                        <option key={customer.id} value={customer.id}>{customer.name}</option>
                    ))}
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="name">
                <Form.Label>
                    {t('predefinedconfiguration.columns.name')} *
                </Form.Label>
                <Form.Control
                    required
                    type="text"
                    placeholder={t('template.columns.name')}
                    name="name"
                    value={data.name}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="type">
                <Form.Label>
                    {t('predefinedconfiguration.columns.type')}
                </Form.Label>
                <Form.Control
                    required as="select"
                    placeholder={t('predefinedconfiguration.columns.type')}
                    name="type"
                    value={data.type}
                    onChange={handleTypeChange}
                >
                    <option>NEXT</option>
                    <option>SHAREPOINT_FOLDERS</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="params">
                <Form.Label>
                    {t('predefinedconfiguration.columns.params')}
                </Form.Label>
                <br/>
                <table style={{width: '100%'}}>
                    { Object.entries(data.params).length > 0 &&
                        <thead>
	                        <tr>
	                            <th>
	                                {t('predefinedconfiguration.params.key')}
	                            </th>
	                            <th>
	                                {t('predefinedconfiguration.params.value')}
	                            </th>
	                        </tr>
                        </thead>
                    }
                    <tbody>
		                {Object.entries(data.params).map(([key, value], index) => {
							return <tr key={index}>
								<td>
									{key}
								</td>
								<td>
									<Form.Control
										type="text"
										placeholder={t('predefinedconfiguration.params.value')}
										name="value"
										value={value}
										onChange={(e) => handleValueChange(e, key)}
									/>
								</td>
							</tr>
		                })}
	                </tbody>
                </table>
            </Form.Group>
        </Form>
    )
}

export default PredefinedConfigurationForm