import React, { useContext } from 'react'
import { Form, Button, Row, Col, Tabs, Tab, Card, InputGroup, Accordion, AccordionContext, AccordionToggle } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronUp, faChevronDown, faCopy } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

function Task({state, tasks, task, parent, provided, handleTaskChanges, showTaskTypes, showPredefined, addTask, addGroup, updateTasks, toggleGroup, suggestionLists}) {

	const {t} = useTranslation('common');

	const onDragEnd = result => {
		console.log('onDragEnd')
        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        console.log(source.index)
        console.log(destination.index)

        var child = task.tasks[source.index]
        child.position = destination.index
        task.tasks.splice(source.index, 1)
        task.tasks.splice(destination.index, 0, child)
        task.tasks.map((child, index) => {
            child.position = index
        })

        updateTasks()
    }

    const addPrecondition = (task) => {
        let precondition = {
            id: uuidv4(),
            customerId: task.customerId,
            type: 'TASK_ANSWER',
            params: {
                TASK_ID: '',
                OPERATOR: 'EQUALS',
                ANSWER: ''
            }
        }
		task.preconditions.push(precondition)
		updateTasks()
    }

    const removePrecondition = (task, precondition) => {
        var index = task.preconditions.indexOf(precondition)
        task.preconditions.splice(index, 1)
		updateTasks()
    }

    const handlePreconditionTypeChanges = (precondition, e) => {
        precondition.type = e.target.value
        precondition.params.TASK_ID = ''
        if (precondition.type == 'TASK_ANSWER') {
            precondition.params.OPERATOR = 'EQUALS'
            precondition.params.ANSWER = ''
        } else {
            delete precondition.params.OPERATOR
            delete precondition.params.ANSWER
        }
		updateTasks()
    }

    const handlePreconditionParamChanges = (precondition, e) => {
        precondition.params[e.target.name] = e.target.value
		updateTasks()
    }

    const getAllTasks = () => {
        var result = []
        tasks.forEach(task => {
            result.push(task)
	        if (task.type == 'GROUP') {
	            var children = visitTask(task)
				children.forEach(child => {
					result.push(child)
				})
	        }
        })
        return result
    }

    const visitTask = (task) => {
        var result = []

        task.tasks.forEach(child => {
            result.push(child)
            if (child.type == 'GROUP') {
                var children = visitTask(child)
				children.forEach(child => {
					result.push(child)
				})
            }
        })

        return result
    }

    function removeTask(e, task) {
        e.preventDefault();

        if (parent) {
            parent.tasks.splice(parent.tasks.indexOf(task), 1)

            parent.tasks.forEach((task, index) => {
	            task.position = index
	        })
        } else {
            tasks.splice(tasks.indexOf(task), 1)

	        tasks.forEach((task, index) => {
	            task.position = index
	        })
        }

        updateTasks()
    }

    function copyTask(e, task) {
        e.preventDefault();

        var taskCopy = {...task}
        taskCopy.id = uuidv4()
        taskCopy.dateCreated = null
        taskCopy.lastUpdated = null

        taskCopy.tasks = []
        if (task.type === 'GROUP') {
            taskCopy.tasks = copyChildren(task)
        }

        taskCopy.preconditions = []
        task.preconditions.forEach(precondition => {
            var preconditionCopy = copyPrecondition(precondition)
            taskCopy.preconditions.push(preconditionCopy)
        })

        if (parent) {
            taskCopy.position = parent.tasks.length + 1
            parent.tasks.push(taskCopy)
        } else {
            taskCopy.position = tasks.length + 1
            tasks.push(taskCopy)
        }

        updateTasks()
    }

    function copyChildren(task) {
	    var result = []
        task.tasks.forEach(task => {
            var taskCopy = {...task}
            taskCopy.id = uuidv4()
            taskCopy.dateCreated = null
            taskCopy.lastUpdated = null

            taskCopy.tasks = []
            if (task.type === 'GROUP') {
                taskCopy.tasks = copyChildren(task)
            }

            taskCopy.preconditions = []
	        task.preconditions.forEach(precondition => {
	            var preconditionCopy = copyPrecondition(precondition)
	            taskCopy.preconditions.push(preconditionCopy)
	        })
	        result.push(taskCopy)
        })
        return result
    }

    function copyPrecondition(precondition) {
        var preconditionCopy = {...precondition}
        preconditionCopy.id = uuidv4()
        preconditionCopy.params = {...precondition.params}
        return preconditionCopy
    }

    return (
        <div {...provided.draggableProps} ref={provided.innerRef}>
	        <div>
				<table className="table border" style={{width: '100%', marginBottom: '0', tableLayout: 'fixed'}}>
					<tbody>
						<tr>
							<td style={{textAlign: 'center', verticalAlign: 'middle', width: '40px'}}>
								<span {...provided.dragHandleProps}>
									<FontAwesomeIcon icon={faGripVertical}/>
								</span>
							</td>
							<td style={{verticalAlign: 'top'}}>
								<InputGroup className="mb-3">
									<Form.Control required type="text" placeholder={t('task.columns.name')} name="name" value={task.name} onChange={e => handleTaskChanges(task, e)}/>
									{ (task.type == 'PHOTO' || task.type == 'DRAWING') &&
										<InputGroup.Text id="basic-addon2">
			                                <span title={t('task.help.name')}>?</span>
			                            </InputGroup.Text>
									}
								</InputGroup>
							</td>
							<td>
								<TextareaAutosize className="form-control" placeholder={t('task.columns.description')} name="description" value={task.description} onChange={e => handleTaskChanges(task, e)} minRows="1"/>
							</td>
							{ showTaskTypes() &&
								<td style={{verticalAlign: 'top'}}>
									{ task.type == 'GROUP' &&
										<span>
											{t('task.type.group')}
										</span>
									}
									{ task.type != 'GROUP' &&
										<>
											<Form.Control required as="select" name="type" value={task.type} onChange={e => handleTaskChanges(task, e)}>
							                    <option value="PHOTO">{t('task.type.photo')}</option>
							                    {/*
								                    <option value="VIDEO">{t('task.type.video')}</option>
								                    <option value="FILE">{t('task.type.file')}</option>
								                */}
							                    <option value="TEXT">{t('task.type.text')}</option>
							                    <option value="CHECKBOX">{t('task.type.checkbox')}</option>
							                    <option value="SINGLE_CHOICE">{t('task.type.singlechoice')}</option>
							                    <option value="MULTI_CHOICE">{t('task.type.multichoice')}</option>
							                    <option value="SINGLE_CHOICE_SUGGESTION">{t('task.type.singlechoicesuggestion')}</option>
							                    <option value="MULTI_CHOICE_SUGGESTION">{t('task.type.multichoicesuggestion')}</option>
							                    <option value="INTEGER">{t('task.type.integer')}</option>
							                    <option value="DECIMAL">{t('task.type.decimal')}</option>
							                    <option value="DRAWING">{t('task.type.drawing')}</option>
							                    <option value="HEADER">{t('task.type.header')}</option>
							                    <option value="FILE">{t('task.type.file')}</option>
							                    { showPredefined() && state.predefinedEnabled &&
							                        <option value="PREDEFINED">{t('task.type.predefined')}</option>
							                    }
							                    {/*
							                        <option value="DATE">{t('task.type.date')}</option>
								                    <option value="DATE_RANGE">{t('task.type.daterange')}</option>
								                    <option value="TIME">{t('task.type.time')}</option>
								                    <option value="DATE_TIME">{t('task.type.datetime')}</option>
							                    */}
							                </Form.Control>
							                { (task.type === 'SINGLE_CHOICE' || task.type === 'MULTI_CHOICE') &&
						                        <InputGroup className="mb-3">
						                            <InputGroup.Text id="basic-addon1">
						                                {t('task.columns.options')}:
						                            </InputGroup.Text>
													<Form.Control required type="text" placeholder={t('task.columns.options')} name="options" value={task.options || ''} onChange={e => handleTaskChanges(task, e)} style={{display: 'inline-block'}}/>
													<InputGroup.Text id="basic-addon2">
						                                <span title={t('task.help.options')}>?</span>
						                            </InputGroup.Text>
												</InputGroup>
							                }
							                { task.type === 'CHECKBOX' &&
						                        <InputGroup className="mb-3">
						                            <InputGroup.Text id="basic-addon1">
						                                {t('task.columns.text')}:
						                            </InputGroup.Text>
													<Form.Control required type="text" placeholder={t('task.columns.text')} name="options" value={task.options || ''} onChange={e => handleTaskChanges(task, e)} style={{display: 'inline-block'}}/>
												</InputGroup>
							                }
							                { (task.type === 'SINGLE_CHOICE_SUGGESTION' || task.type === 'MULTI_CHOICE_SUGGESTION') &&
						                        <InputGroup className="mb-3">
						                            <InputGroup.Text id="basic-addon1">
						                                {t('task.columns.options')}:
						                            </InputGroup.Text>
						                            <Form.Control required as="select" name="options" value={task.options} onChange={e => handleTaskChanges(task, e)}>
						                                <option value="">{t('template.choosesuggestionlist')}</option>
						                                { suggestionLists.map(suggestionList => {
						                                    return <option key={suggestionList.id} value={suggestionList.id}>{suggestionList.name}</option>
						                                })}
									                </Form.Control>
												</InputGroup>
							                }
							                { task.type === 'FILE' &&
						                        <InputGroup className="mb-3">
						                            <InputGroup.Text id="basic-addon1">
						                                {t('task.columns.fileextensions')}:
						                            </InputGroup.Text>
													<Form.Control type="text" placeholder={t('task.columns.fileextensions')} name="options" value={task.options || ''} onChange={e => handleTaskChanges(task, e)} style={{display: 'inline-block'}}/>
													<InputGroup.Text id="basic-addon2">
						                                <span title={t('task.help.fileextensions')}>?</span>
						                            </InputGroup.Text>
												</InputGroup>
							                }
							                { task.type === 'PREDEFINED' &&
							                    <Form.Control required as="select" name="options" value={task.options} onChange={e => handleTaskChanges(task, e)}>
								                    <option value="">{t('task.columns.key')}</option>
						                            <option>PROJECT_NOTE</option>
								                    <option>PROJECT_MANAGER_NAME</option>
								                    <option>PROJECT_MANAGER_NUMBER</option>
								                    <option>PROJECT_MANAGER_EMAIL</option>
								                    <option>WORK_LEADER_NAME</option>
								                    <option>WORK_LEADER_NUMBER</option>
								                    <option>WORK_LEADER_EMAIL</option>
								                    <option>CUSTOMER_CONTACT_NAME</option>
								                    <option>CUSTOMER_CONTACT_NUMBER</option>
								                    <option>CUSTOMER_CONTACT_EMAIL</option>
								                    <option>CUSTOMER_NAME</option>
								                </Form.Control>
							                }
							                { (task.type === 'HEADER') &&
						                        <InputGroup className="mb-3">
						                            <InputGroup.Text id="basic-addon1">
						                                {t('task.columns.size')}:
						                            </InputGroup.Text>
													<Form.Control as="select" name="options" value={task.options || ""} onChange={e => handleTaskChanges(task, e)}>
									                    <option value="">{t('task.columns.size')}</option>
							                            <option value="1">{t('task.values.normal')}</option>
							                            <option value="2">{t('task.values.large')}</option>
							                            <option value="3">{t('task.values.larger')}</option>
							                            <option value="4">{t('task.values.largest')}</option>
									                </Form.Control>
												</InputGroup>
							                }
						                </>
					                }
				                </td>
			                }
			                <td style={{verticalAlign: 'top'}}>
			                    { task.type != 'HEADER' && task.type != 'GROUP' &&
									<Form.Control required as="select" name="required" value={task.required} onChange={e => handleTaskChanges(task, e)}>
					                    <option value="NO">{t('task.required.no')}</option>
					                    { task.type === 'PHOTO' &&
					                        <option value="NO_WITH_VALID_EXPLANATION">{t('task.required.nowithvalidexplanation')}</option>
					                    }
					                    { task.type === 'FILE' &&
					                        <option value="NO_WITH_LATER_UPLOAD">{t('task.required.nowithlaterupload')}</option>
					                    }
					                    <option value="YES">{t('task.required.yes')}</option>
					                </Form.Control>
					            }
			                </td>
			                { showTaskTypes() &&
								<td style={{verticalAlign: 'top'}}>
									{ task.preconditions.map((precondition, index) => {
										return <Accordion key={precondition.id} defaultActiveKey={precondition.dateCreated ? '' : '0'}>
											<Card>
												<Card.Header style={{padding: '0px', background: 'none'}}>
													<div className="clearfix">
														<div className="float-left" style={{padding: '5px 10px 5px 10px'}}>
															{t('precondition.label')} {index + 1}
														</div>
														<div className="float-right">
															<ContextAwareToggle eventKey="0"/>
												        </div>
													</div>
												</Card.Header>
												<Accordion.Collapse eventKey="0">
													<Card.Body>
														<label>
															{t('precondition.columns.type')}
														</label>
														<Form.Control required as="select" name="type" value={precondition.type} onChange={e => handlePreconditionTypeChanges(precondition, e)}>
										                    <option value="TASK_ANSWER">{t('precondition.types.taskanswer')}</option>
										                    <option value="TASK_PHOTOS_TAKEN">{t('precondition.types.taskphotostaken')}</option>
										                    <option value="TASK_NO_PHOTOS_TAKEN">{t('precondition.types.tasknophotostaken')}</option>
										                </Form.Control>
										                { precondition.type == 'TASK_ANSWER' &&
										                    <>
										                        <label>
																	{t('precondition.params.task')}
																</label>
																<Form.Control required as="select" name="TASK_ID" value={precondition.params.TASK_ID} onChange={e => handlePreconditionParamChanges(precondition, e)}>
												                    <option value="">{t('precondition.choosetask')}</option>
												                    { getAllTasks().filter(otherTask => otherTask.id != task.id && ['TEXT', 'CHECKBOX', 'SINGLE_CHOICE', 'MULTI_CHOICE', 'INTEGER', 'DECIMAL', 'SINGLE_CHOICE_SUGGESTION', 'MULTI_CHOICE_SUGGESTION'].includes(otherTask.type)).map(task => {
												                        return <option value={task.id} key={task.id}>{task.name}</option>
												                    })}
												                </Form.Control>
												                <label>
																	{t('precondition.params.operator')}
																</label>
																<Form.Control required as="select" name="OPERATOR" value={precondition.params.OPERATOR} onChange={e => handlePreconditionParamChanges(precondition, e)}>
												                    <option value="EQUALS">{t('precondition.operators.equals')}</option>
												                    <option value="NOT_EQUALS">{t('precondition.operators.notequals')}</option>
												                </Form.Control>
												                <label>
																	{t('precondition.params.answer')}
																</label>
																<Form.Control required type="text" placeholder={t('precondition.params.answer')} name="ANSWER" value={precondition.params.ANSWER} onChange={e => handlePreconditionParamChanges(precondition, e)}/>
									                        </>
									                    }
									                    { (precondition.type == 'TASK_PHOTOS_TAKEN' || precondition.type == 'TASK_NO_PHOTOS_TAKEN') &&
										                    <>
										                        <label>
																	{t('precondition.params.task')}
																</label>
																<Form.Control required as="select" name="TASK_ID" value={precondition.params.TASK_ID} onChange={e => handlePreconditionParamChanges(precondition, e)}>
												                    <option value="">{t('precondition.choosetask')}</option>
												                    { tasks.filter(otherTask => otherTask.id != task.id && otherTask.type == 'PHOTO').map(task => {
												                        return <option value={task.id} key={task.id}>{task.name}</option>
												                    })}
												                </Form.Control>
									                        </>
									                    }
									                    <br/>
									                    <Button
												            onClick={(e) => removePrecondition(task, precondition)}
												            title={t("crudtable.delete.button")}
												            color="info"
												            variant="outline-danger"
												            style={{ width: '100%' }}>
												            <FontAwesomeIcon icon={faTrash}/> {t('precondition.delete')}
												        </Button>
									                </Card.Body>
								                </Accordion.Collapse>
											</Card>
										</Accordion>
									})}
									<Button
					                    title={t('task.addprecondition')}
					                    onClick={e => addPrecondition(task)}
					                    color="info"
					                    variant="outline-primary"
					                    disabled={tasks.filter(otherTask => otherTask.id != task.id).length == 0}>
					                    <FontAwesomeIcon icon={faPlus}/> {t('task.addprecondition')}
					                </Button>
			                    </td>
		                    }
							<td style={{textAlign: 'right', verticalAlign: 'top', width: '130px'}}>
								{ task.type === 'GROUP' &&
									<Button
							            onClick={(e) => toggleGroup(e, task)}
							            title={t("task.copytask")}
							            size="sm"
							            color="info"
							            variant="outline-primary"
							            style={{marginRight: '5px'}}>
							            <FontAwesomeIcon icon={task.expanded ? faChevronUp : faChevronDown}/>
							        </Button>
								}
								<Button
						            onClick={(e) => copyTask(e, task)}
						            title={t("task.copytask")}
						            size="sm"
						            color="info"
						            variant="outline-primary">
						            <FontAwesomeIcon icon={faCopy}/>
						        </Button>
								<Button
						            onClick={(e) => removeTask(e, task)}
						            title={t("crudtable.delete.button")}
						            size="sm"
						            color="info"
						            variant="outline-danger"
						            style={{marginLeft: '5px'}}>
						            <FontAwesomeIcon icon={faTrash}/>
						        </Button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{ task.type == 'GROUP' && task.expanded &&
				<div style={{paddingLeft: '20px'}}>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId={task.id}>
							{ provided => (
								<div ref={provided.innerRef} {...provided.droppableProps}>
									{ task.tasks.map((child, index) => {
										return <Draggable key={child.id} draggableId={child.id} index={index}>
											{ provided => (
												<Task
													state={state}
													tasks={tasks}
													task={child}
													parent={task}
													provided={provided}
													handleTaskChanges={handleTaskChanges}
													showTaskTypes={showTaskTypes}
													showPredefined={showPredefined}
													removeTask={removeTask}
													addTask={addTask}
													addGroup={addGroup}
													updateTasks={updateTasks}
													toggleGroup={toggleGroup}
													suggestionLists={suggestionLists}
												/>
											)}
										</Draggable>
									})}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
	                </DragDropContext>
	                { task.type == 'GROUP' &&
						<Button
		                    title={t('template.addtask')}
		                    onClick={e => addTask(e, task)}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('template.addtask')}
		                </Button>
					}
					{ showTaskTypes() && task.type == 'GROUP' &&
		                <Button
		                    title={t('template.addgroup')}
		                    onClick={e => addGroup(e, task)}
		                    color="info"
		                    variant="outline-primary"
		                    style={{marginLeft: '5px'}}>
		                    <FontAwesomeIcon icon={faPlus}/> {t('template.addgroup')}
		                </Button>
	                }
                </div>
			}
		</div>
    )
}

function ContextAwareToggle({ eventKey, callback }) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionToggle(
		eventKey,
		() => callback && callback(eventKey),
	);

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Button onClick={decoratedOnClick} variant="outline">
		    <FontAwesomeIcon icon={isCurrentEventKey ? faChevronUp : faChevronDown}/>
		</Button>
	);
}

export default Task